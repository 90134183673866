// COLORS
$background-color: #E5E5E5;
$main-title-color: #000000;
$sub-title-color: rgba(0, 0, 0, 0.87);
$text-color-white: #FFFFFF;
$success-color: #43A047;
$fail-color: #B00020;

// VARIABLES
$main-title-font-size: 32px;
$main-title-line-height: 37px;
$paige-padding: 45px 104px;
$subitle-font-size: 20px;
$subtitle-line-height: 24px;
$subtitle-letter-spacing: 0.15px;
$mobile-width: 425px;